<template>
    <div class="container">
      <h2>用户信息表单</h2>
      <form @submit.prevent="submitForm" class="form">
        <div class="form-group">
          <label for="username">用户名:</label>
          <input type="text" id="username" v-model="formData.username" required>
        </div>
        <div class="form-group">
          <label for="role">角色:</label>
          <input type="text" id="role" v-model="formData.role" required>
        </div>
        <div class="form-group">
          <label for="prompt">段落:</label>
          <textarea id="prompt" v-model="formData.prompt" required></textarea>
        </div>
        <button type="submit" class="btn-submit">提交</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        formData: {
          username: '',
          role: '',
          prompt: ''
        }
      };
    },
    methods: {
      async submitForm() {
        try {
          const username = 'zyh'; // 替换为实际的用户名
          const password = 'pzyh'; // 替换为实际的密码
          const credentials = `${username}:${password}`;
          const encodedCredentials = btoa(credentials);
  
          const response = await fetch('http://127.0.0.1:3000:3000/submit/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Basic ${encodedCredentials}`
            },
            body: JSON.stringify(this.formData),
            mode: 'cors'
          });
  
          if (!response.ok) {
            throw new Error('提交失败');
          }
  
          console.log('提交成功');
          this.formData.username = '';
          this.formData.role = '';
          this.formData.prompt = '';
        } catch (error) {
          console.error('提交出错:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  h2 {
    text-align: center;
  }
  
  .form {
    margin-top: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn-submit {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-submit:hover {
    background-color: #0056b3;
  }
  </style>
  