<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="card" style="margin-top: 20px;" >
                    <div class="card-header">
                        <span style="font-size: 130%;" >我的密钥信息</span><br>
                        <span style="font-size: 80%;" >注：每位用户只能存在一个密钥，可修改</span>
                        <button type="button" class="btn btn-primary float-end" data-bs-toggle="modal" data-bs-target="#add-key-button">
                        创建密钥
                        </button>
                        <!-- Modal -->
                        <div class="modal fade" id="add-key-button" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-xl" >
                                <div class="modal-content">
                                    <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="exampleModalLabel"> 创建密钥</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="mb-3">
                                            <label for="add-xinghuoappid" class="form-label">星火APPID</label>
                                            <input v-model="keyadd.xinghuoappid" type="text" class="form-control" id="add-xinghuoappid" placeholder="请输入角色名称">
                                          </div>
                                          <div class="mb-3">
                                            <label for="add-xinghuoapiSecret" class="form-label">星火APISecret</label>
                                            <input v-model="keyadd.xinghuoapiSecret" type="text" class="form-control" id="add-xinghuoapiSecret" rows="9" placeholder="请输入星火APISecret">
                                          </div>
                                          <div class="mb-3">
                                            <label for="add-xinghuoapiKey" class="form-label">星火APIKey</label>
                                            <input v-model="keyadd.xinghuoapiKey" type="text" class="form-control" id="add-xinghuoapiKey" rows="9" placeholder="请输入星火APIKey">
                                          </div>
                                          <div class="mb-3">
                                            <label for="add-xinghuoUrl" class="form-label">星火URL</label>
                                            <input v-model="keyadd.xinghuoUrl" type="text" class="form-control" id="add-xinghuoUrl" rows="9" placeholder="请输入星火URL">
                                          </div>
                                          <div class="mb-3">
                                            <label for="add-baiduapiKey" class="form-label">百度APIKey</label>
                                            <input v-model="keyadd.baiduapiKey" type="text" class="form-control" id="add-baiduapiKey" rows="9" placeholder="请输入百度APIKey">
                                          </div>
                                          <div class="mb-3">
                                            <label for="add-baiduapiSecret" class="form-label">百度APISecret</label>
                                            <input v-model="keyadd.baiduapiSecret" type="text" class="form-control" id="add-baiduapiSecret" rows="9" placeholder="请输入百度APISecret">
                                          </div>
                                          <div class="mb-3">
                                            <label for="add-huoshanAppid" class="form-label">火山APPID</label>
                                            <input v-model="keyadd.huoshanAppid" type="text" class="form-control" id="add-huoshanAppid" rows="9" placeholder="请输入百度APISecret">
                                          </div>
                                          <div class="mb-3">
                                            <label for="add-huoshanToken" class="form-label">火山Token</label>
                                            <input v-model="keyadd.huoshanToken" type="text" class="form-control" id="add-huoshanToken" rows="9" placeholder="请输入百度APISecret">
                                          </div>
                                    </div>
                                    <div class="modal-footer">
                                    <div class="error-message">{{ keyadd.error_message }}</div>
                                    <button type="button" class="btn btn-primary" @click="add_key" >创建</button>
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>密钥</th>
                                    <th>创建时间</th>
                                    <th>修改时间</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="key && key.id">
                                    <td>{{ key.username }}</td>
                                    <td>{{ key.createtime }}</td>
                                    <td>{{ key.modifytime }}</td>
                                    <td>
                                        <button type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#update-bot-modal-'+key.id" >修改</button>
                                        <button type="button" class="btn btn-danger"  data-bs-toggle="modal" data-bs-target="#deleteModal">删除</button>
                                        <!-- 删除 -->
                                        <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="exampleModalLabel">删除提示</h1>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                您确认要删除嘛？
                                                </div>
                                                <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">关闭</button>
                                                <button type="button" class="btn btn-danger" @click="remove_key(key)">删除</button>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <!-- 修改 -->
                                        <div class="modal fade" :id="'update-bot-modal-'+key.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-xl" >
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                    <h1 class="modal-title fs-5">修改密钥信息</h1>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="mb-3">
                                                            <label for="add-xinghuoappid" class="form-label">星火APPID</label>
                                                            <input v-model="key.xinghuoappid" type="text" class="form-control" id="add-xinghuoappid" placeholder="请输入星火APPID">
                                                          </div>
                                                          <div class="mb-3">
                                                            <label for="add-xinghuoapiSecret" class="form-label">星火APISecret</label>
                                                            <input v-model="key.xinghuoapiSecret" class="form-control" id="add-xinghuoapiSecret" rows="9" placeholder="请输入星火APISecret">
                                                          </div>
                                                          <div class="mb-3">
                                                            <label for="add-xinghuoapiKey" class="form-label">星火APIKey</label>
                                                            <input v-model="key.xinghuoapiKey" class="form-control" id="add-xinghuoapiKey" rows="9" placeholder="请输入星火APIKey">
                                                          </div>
                                                          <div class="mb-3">
                                                            <label for="add-xinghuoUrl" class="form-label">星火URL</label>
                                                            <input v-model="key.xinghuoUrl" class="form-control" id="add-xinghuoUrl" rows="9" placeholder="请输入星火URL">
                                                          </div>
                                                          <div class="mb-3">
                                                            <label for="add-baiduApikey" class="form-label">百度APIKey</label>
                                                            <input v-model="key.baiduApikey" class="form-control" id="add-baiduApikey" rows="9" placeholder="请输入百度APIKey">
                                                          </div>
                                                          <div class="mb-3">
                                                            <label for="add-baiduSecretkey" class="form-label">百度APISecret</label>
                                                            <input v-model="key.baiduSecretkey" class="form-control" id="add-baiduSecretkey" rows="9" placeholder="请输入百度APISecret">
                                                          </div>
                                                          <div class="mb-3">
                                                            <label for="add-huoshanAppid" class="form-label">火山Appid</label>
                                                            <input v-model="key.huoshanAppid" class="form-control" id="add-huoshanAppid" rows="9" placeholder="请输入百度APISecret">
                                                          </div>
                                                          <div class="mb-3">
                                                            <label for="add-huoshanToken" class="form-label">火山Token</label>
                                                            <input v-model="key.huoshanToken" class="form-control" id="add-huoshanToken" rows="9" placeholder="请输入百度APISecret">
                                                          </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                    <div class="error-message">{{ key.error_message }}</div>
                                                    <button type="button" class="btn btn-primary" @click="update_key(key)" >保存修改</button>
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                          </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
     </template>
     
     <script>
     import {ref,reactive} from 'vue'
     import $ from 'jquery'
     import {useStore} from 'vuex'
     import {Modal} from 'bootstrap/dist/js/bootstrap'
     
     
     export default {
         setup(){
            const store = useStore();
            let key = ref([]);
    
            const keyadd = reactive({
                xinghuoappid: "",
                xinghuoapiSecret: "",
                xinghuoapiKey: "",
                xinghuoUrl: "",
                baiduapiKey: "",
                baiduapiSecret: "",
                huoshanAppid: "",
                huoshanToken: "",
                error_message: "",
            })
    
            const add_key = () => {
                $.ajax({
                    url: "https://aiserver.arvreducation.org/user/key/add",
                    type: "post",
                    data: {
                        Xinghuoappid: keyadd.xinghuoappid,
                        XinghuoapiSecret: keyadd.xinghuoapiSecret,
                        XinghuoapiKey: keyadd.xinghuoapiKey,
                        XinghuoUrl: keyadd.xinghuoUrl,
                        baiduApikey: keyadd.baiduapiKey,
                        baiduSecretkey: keyadd.baiduapiSecret,
                        huoshanAppid: keyadd.huoshanAppid,
                        huoshanToken: keyadd.huoshanToken,
                    },
                    headers: {
                        Authorization : "Bearer " + store.state.user.token,
                    },
                    success(resp){
                        if(resp.error_message === "success"){
                            keyadd.xinghuoappid = "";
                            keyadd.xinghuoapiSecret = "";
                            keyadd.xinghuoapiKey = "";
                            keyadd.xinghuoUrl = "";
                            keyadd.baiduapiKey = "";
                            keyadd.baiduapiSecret = "";
                            keyadd.huoshanAppid = "";
                            keyadd.huoshanToken = "";
                            Modal.getInstance("#add-key-button").hide();
                            refresh_keys();
                        }
                        else{
                            keyadd.error_message = resp.error_message;
                        }
                    }
                })
            }
    
            const refresh_keys = () => {
                $.ajax({
                    url: "https://aiserver.arvreducation.org/user/key/get",
                    type: "get",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },
                    success(resp)
                    {
                        key.value = resp;
                        console.log(key.value)
                    }
                    // success(resp){
                    //     console.log(resp);
                    // },
                })
            }
    
            refresh_keys();
    
    
            const update_key = (keys) => {
                $.ajax({
                    url: "https://aiserver.arvreducation.org/user/key/update/",
                    type: "post",
                    data : {
                        key_id : keys.id,
                        Xinghuoappid: keys.xinghuoappid,
                        XinghuoapiSecret: keys.xinghuoapiSecret,
                        XinghuoapiKey: keys.xinghuoapiKey,
                        XinghuoUrl: keys.xinghuoUrl,
                        baiduApikey: keys.baiduApikey,
                        baiduSecretkey: keys.baiduSecretkey,
                        huoshanAppid: keys.huoshanAppid,
                        huoshanToken: keys.huoshanToken,
                    },
                    headers: {
                        Authorization : "Bearer " + store.state.user.token,
                    },
                    success(resp){
                        if(resp.error_message === "success"){
                            Modal.getInstance('#update-bot-modal-'+keys.id).hide();
                            refresh_keys();
                        }
                        else{
                            console.log(keys)
                            keys.error_message = resp.error_message;
                        }
                    },
                    error(resp){
                        console.log(keys.xinghuoKey)
                        console.log(resp);
                    }
                })
            }
    
            const remove_key = (key) => {
                $.ajax({
                    url: "https://aiserver.arvreducation.org/user/key/remove",
                    type: "post",
                    data: {
                        key_id: key.id,
                    },
                    headers: {
                        Authorization : "Bearer " + store.state.user.token,
                    },
                    success(resp){
                        if(resp.error_message === "success"){
                            Modal.getInstance("#deleteModal").hide();
                            refresh_keys();
                        }
                    },
                    error(resp){
                        console.log(resp);
                    }
                })
            }
    
            return {
                key,
                keyadd,
                add_key,
                remove_key,
                update_key,
            }
           
    
    
         }
     }
     
     </script>
     
     <style scoped>
     div.error-message{
        color: red;
     }
     </style>