<template>
<nav class="navbar navbar-expand-lg bg-body-tertiary" >
  <div class="container">
    <router-link class="navbar-brand" :to="{name: 'home'}" >
      <img src="../assets/images/title.png" alt="Logo" style="width: 200px; height: 50px;">
     
    </router-link>
    <div class="collapse navbar-collapse" id="navbarText">
     <ul class="navbar-nav ">
      <li class="nav-item">
        <router-link :class="route_name == 'pk_index' ?  'nav-link active' : 'nav-link'" :to="{name: 'pk_index'}" >角色定义</router-link>
      </li>
        <li class="nav-item">
          <router-link :class="route_name == 'key_index' ?  'nav-link active' : 'nav-link'" :to="{name: 'key_index'}" >密钥</router-link>
        </li>
        
      </ul> 
         <!-- 
        <li class="nav-item">
          <router-link :class="route_name == 'record_index' ?  'nav-link active' : 'nav-link'" :to="{name: 'record_index'}" >查询</router-link>
        </li>
        <li class="nav-item">
          <router-link :class="route_name == 'ranklist_index' ?  'nav-link active' : 'nav-link'" :to="{name: 'ranklist_index'}" >修改</router-link>
        </li>
      </ul> -->
      <ul class="navbar-nav ms-auto" v-if="$store.state.user.is_login"  >
        <li class="nav-item dropdown" >
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ $store.state.user.username }}
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#" @click="logout" >退出</a></li>
          </ul>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto" v-else >
        <li class="nav-item ">
          <router-link class="nav-link " :to="{name: 'user_account_login'}" role="button" >
            登录
          </router-link>
        </li>
        <li class="nav-item ">
          <router-link class="nav-link " :to="{name: 'user_account_register'}" role="button" >
            注册
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>

import {useRoute} from 'vue-router'
import { computed } from 'vue'
import {useStore} from 'vuex';
export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    let route_name = computed(() => route.name);
    
    

    const logout = () => {
        store.dispatch("logout");
    }

    return{
      route_name,
      logout
    }
    
  }
}

</script>

<style scoped >

</style>