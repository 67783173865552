<template>
<NavBar></NavBar>
  <router-view></router-view>
</template>

<script>
import NavBar from './components/NavBar.vue';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"
//import $ from 'jquery'
export default {
    components: {
      NavBar
    },
/*
    setup(){

        $.ajax({
          url:"http://127.0.0.1:3000:3000/user/account/info",
          type: "get",
          headers: {
            Authorization:"Bearer " + "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIwYTgzOTlkN2Q1ZmE0NGNiOTVlOTRiOWQwNDlkZTg5MiIsInN1YiI6IjEiLCJpc3MiOiJzZyIsImlhdCI6MTcxMTI0OTU0MCwiZXhwIjoxNzEyNDU5MTQwfQ.K9KPOz3LWaNZS871UIyAze3jby_UWRmUPuPw2niMJwM",
          },
          success(resp){
                console.log(resp);
            },
            error(resp){
                console.log(resp);
            }
        });
        $.ajax({
          url:"http://127.0.0.1:3000:3000/user/account/register",
          type: "post",
          data:{
            username: "yxc",
            password:"123",
            confirmedPassword:"123",
          },
          success(resp){
            console.log(resp);
          },
          error(resp){
            console.log(resp);
          }
        });
    } */
  } 
</script>


<style>
body{
  /*background-image: url("assets/images/title.png");*/
  background-size: cover;
  background-color: antiquewhite;
}
</style>


