<template>
    <NavBar></NavBar>
    <div>
      <button @click="testConnection">测试连接</button>
    </div>
    
  </template>
  
  <script>
  import $ from 'jquery'
import NavBar from '@/components/NavBar'
  export default {
    components: {
        NavBar
    },
    setup(){
        $.ajax({
            url:"http://127.0.0.1:3000:3000/submit/",
            type:"post",
            data:{
                username: 'zyh',
                password: "pzyh",
            },
            success(resp){
                console.log(resp);
            },
            error(resp){
                console.log(resp);
            }
        })
    }
        
    
  }


  </script>
  