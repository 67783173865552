import { createRouter, createWebHistory } from 'vue-router'
import PkIndexView from '@/views/pk/PkIndexView'
import RecordIndexView from '@/views/record/RecordIndexView'
import RankListView from '@/views/ranklist/RankListView'
import UserIndexView from '@/views/user/UserIndexView'
import NotFound from '@/views/error/NotFound'
import UserBotIndexView from '@/views/user/bot/UserBotIndexView'
import FormWritten from '@/views/FormWritten/FormWritten'
import TestCon from '@/views/FormWritten/TestCon'
import UserAccountLoginView from '@/views/user/account/UserAccountLoginView'
import UserAccountRegisterView from '@/views/user/account/UserAccountRegisterView'
import KeyInfoView from './../views/key/KeyInfoView.vue'
import store from '../store'
const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/promptInfo/",
    meta: {
      requestAuth: true,
      title: 'Home Page' // 设置页面标题
  
    }
  },
  {
    path: "/promptInfo/",
    name: "pk_index",
    component: PkIndexView,
    meta: {
      requestAuth: true,
      title: 'Home Page' // 设置页面标题
    }
  },
  {
    path: "/keyinfo/",
    name: "key_index",
    component: KeyInfoView,
    meta: {
      requestAuth: true,
      title: 'Home Page' // 设置页面标题
    }
  },
  {
    path: "/record/",
    name: "record_index",
    component: RecordIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/ranklist/",
    name: "ranklist_index",
    component: RankListView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/",
    name: "user_index",
    component: UserIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/bot",
    name: "user_bot_index",
    component: UserBotIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/account/login",
    name: "user_account_login",
    component: UserAccountLoginView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path:"/user/account/register",
    name:"user_account_register",
    component: UserAccountRegisterView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/test/",
    name: "test_index",
    component: TestCon,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/fw/",
    name: "form_index",
    component: FormWritten,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/404/",
    name: "404",
    component: NotFound,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/:catchAll(.*)",
    name: "not_found",
    redirect: "/404/",
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    if(to.meta.requestAuth && !store.state.user.is_login){
      next({name: "user_account_login"});
    }else{
      next();
    }
})

export default router
