<template>
    <div class="container">
        <div class="row">
            <div class="card" style="margin-top: 20px;" >
                <div class="card-header">
                    <span style="font-size: 120%">我的信息</span>
                    <button type="button" class="btn btn-primary float-end" data-bs-toggle="modal" data-bs-target="#add-btn">
                        创建Bot
                    </button>
                    <!-- Button trigger modal -->
                    
                    <!-- Modal -->
                    <div class="modal fade" id="add-btn">
                        <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h1 class="modal-title fs-5">创建Bot</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="mb-3">
                                      <label for="add-title" class="form-label">名称</label>
                                      <input v-model="botadd.title" type="text" class="form-control" id="add-title" aria-describedby="请输入bot名称">
                                    </div>
                                    <div class="mb-3">
                                      <label for="add-description" class="form-label">简介</label>
                                      <textarea v-model="botadd.description" class="form-control" id="add-description" placeholder="请输入bot简介" ></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label for="add-code" class="form-label">代码</label>
                                        <textarea v-model="botadd.content" class="form-control" id="add-code" rows="7" placeholder="请输入bot代码" ></textarea>
                                    </div>
                                    <div class="mb-3 form-check">
                                      <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                      <label class="form-check-label" for="exampleCheck1">Check me out</label>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                  </form>
                            </div>
                            <div class="modal-footer">
                            <div class="error-message" >{{ botadd.error_message }}</div>
                            <button type="button" class="btn btn-primary" @click="add_bot" >创建</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-striped table-hover" >
                        <thead>
                            <tr>
                                <th>Bot名称</th>
                                <th>创建时间</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="bot in bots" :key="bot.id">
                                <td>{{ bot.title }}</td>
                                <td>{{ bot.createtime }}</td>
                                <td>
                                    <button type="button" class="btn btn-secondary" style="margin-right:10px" data-bs-toggle="modal" :data-bs-target="'#update-modal-' + bot.id">修改</button>
                                    <button type="button" class="btn btn-danger" @click="remove_bot(bot)">删除</button>

                                    <!-- Modal -->
                                    <div class="modal fade" :id="'update-modal-' + bot.id">
                                        <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                            <h1 class="modal-title fs-5">创建Bot</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <form>
                                                    <div class="mb-3">
                                                    <label for="add-title" class="form-label">名称</label>
                                                    <input v-model="bot.title" type="text" class="form-control" id="add-title" aria-describedby="请输入bot名称">
                                                    </div>
                                                    <div class="mb-3">
                                                    <label for="add-description" class="form-label">简介</label>
                                                    <textarea v-model="bot.description" class="form-control" id="add-description" placeholder="请输入bot简介" ></textarea>
                                                    </div>
                                                    <div class="mb-3">
                                                        <label for="add-code" class="form-label">代码</label>
                                                        <textarea v-model="bot.content" class="form-control" id="add-code" rows="7" placeholder="请输入bot代码" ></textarea>
                                                    </div>
                                                    <div class="mb-3 form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                    <label class="form-check-label" for="exampleCheck1">Check me out</label>
                                                    </div>
                                                    <button type="submit" class="btn btn-primary">Submit</button>
                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                            <div class="error-message" >{{ bot.error_message }}</div>
                                            <button type="button" class="btn btn-primary" @click="update_bot(bot)" >保存修改</button>
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, reactive} from 'vue'
import $ from 'jquery'
import {useStore} from 'vuex'
import {Modal} from 'bootstrap/dist/js/bootstrap'
export default {
    setup(){
       const store = useStore();
       let bots = ref([]);

       const botadd = reactive({
            title:"",
            description:"",
            content:"",
            error_message:"",
       })

       const refresh_bots = () => {
            $.ajax({
                url: "https://aiserver.arvreducation.org/user/bot/getlist/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp){
                    bots.value = resp;
                }
            })
       }

       refresh_bots();

       const add_bot = () => {
            botadd.error_message = "";
            $.ajax({
                url: "https://aiserver.arvreducation.org/user/bot/add",
                type: "post",
                data:{
                    title: botadd.title,
                    description: botadd.description,
                    content: botadd.content,
                },
                headers:{
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp){
                    if(resp.error_message == "success"){
                        botadd.title = "";
                        botadd.description = "";
                        botadd.content = "";
                        Modal.getInstance("#add-btn").hide();
                        refresh_bots();
                    }else{
                        botadd.error_message = resp.error_message;
                    }
                }
            })
       }

       const remove_bot = (bot) => {
        $.ajax({
                url: "https://aiserver.arvreducation.org/bot/remove",
                type: "post",
                data:{
                    bot_id: bot.id,
                },
                headers:{
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp){
                    if(resp.error_message === "success"){
                        refresh_bots();
                    }
                }
            })
       }

       const update_bot = (bot) => {
            bot.error_message = "";
            $.ajax({
                url: "https://aiserver.arvreducation.org/user/bot/update",
                type: "post",
                data:{
                    bot_id: bot.id,
                    title: bot.title,
                    description: bot.description,
                    content: bot.content,
                },
                headers:{
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp){
                    if(resp.error_message == "success"){
                        Modal.getInstance('#update-modal-' + bot.id).hide();
                        refresh_bots();
                    }else{
                        bot.error_message = resp.error_message;
                    }
                }
            })
       }


  


       return{
        bots,
        botadd,
        add_bot,
        remove_bot,
        update_bot,
       }

    }
}

</script>

<style scoped>
div.error-message{
    color: red;
}
</style>